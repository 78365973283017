<template>
  <div>
    <h2>Repeatable inputs</h2>

    <form @submit.prevent="onSubmit()">
      <RepeatableInputs
        id="test"
        :errors="errors"
        :default-selection="[
          {
            substance: {
              key: 8,
              value: 'Composant 5'

            },
            quantite: 15,
            unite: 'ml',
          },
           {
            substance: {
              key: 1,
              value: 'Substance A'
            },
            quantite: 10,
            unite: 'l',
          },
          {
            substance: {
              key: 8,
              value: 'Composant 32'

            },
            quantite: 5,
            unite: 'ml',
          },
          {
            substance: {
              key: 1,
              value: 'Molécule B'
            },
            quantite: 30,
            unite: 'l',
          },
        ]"
        :items="form.repeatableInputs"
        @selected-items="setDatas($event)"
        isDraggable
      >
      </RepeatableInputs>

      <Btn btnType="submit" text="Modifier" color="primary" />
    </form>

  </div>
</template>

<script>
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'StyleguideFormsAdvancedView',
  components: {
    Btn,
    RepeatableInputs,
  },
  data() {
    const validationSchema = this.yup.object().shape({
      test: this.yup.array().of(
        this.yup.object().shape({
          substance: this.yup.object().required().nullable(),
          quantite: this.yup.string().required().nullable(),
          unite: this.yup.string().required().nullable(),
        }),
      ),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      // console.log(values)
      this.handleValues(values)
    })

    return {
      errors,
      isSubmitting,
      onSubmit,
      form: {
        repeatableInputs: {
          group: [
            {
              label: 'Substance active',
              name: 'substance',
              meta: {
                optionKey: 'id',
                optionValue: 'nom',
                apiEndpoint: 'composant',
                required: 'required',
              },
            },
            {
              label: 'Quantité', name: 'quantite', type: 'number', required: 'required',
            },
            { label: 'Unité', name: 'unite', required: 'required' },
          ],
        },
      },
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  methods: {
    setDatas(event) {
      this.form.repeatableInputs = event.fieldsGroup
    },
    onInputChange(event) {
      console.log('Input change', event)
    },

    handleValues(values) {
      console.log(values)
    },

  },
}
</script>

<style scoped>

</style>
